import { useCallback, useState, createContext, useContext, useEffect } from "react";
import { useAuth } from "./useAuth";
import { useApi } from "../hooks/useApi";

const ActionableContext = createContext();

const useActionable = () => {
  const [actionables, setActionables] = useState([]);
  const { sendRequestAsCallback } = useApi();
  const { userInfo } = useAuth();

  const isActionable = useCallback((key) => {
    return actionables.some((actionableItem) =>
      Array.isArray(actionableItem) ? actionableItem.includes(key) : actionableItem === key
    );
  }, [actionables]
  );

  const isActionableArrayOfStrings = useCallback((keys) => {
    if (Array.isArray(keys)) {
      return keys.some((key) => isActionable(key));
    } else {
      return false;
    }
  }, [isActionable]);

  const addActionable = (actionable) => {
    setActionables((prevActionables) => [...prevActionables, actionable]);
  };

  const removeActionable = (actionable) => {
    setActionables((prevActionables) => {
      return prevActionables.filter((prevActionable) => {
        if (Array.isArray(actionable)) {
          return JSON.stringify(prevActionable) !== JSON.stringify(actionable);
        } else {
          return prevActionable !== actionable;
        }
      });
    });
  };

  /** setting a default actionable item for SSN/TIN for US and Puerto Rico */
  const checkGovernmentId = useCallback(async (userInfo) => {
    const ssnTinActionablePath = ['settings', 'personal_information', 'ssn_tin'];
    let clientHasGovernmentId = true;
    if (!userInfo.hasOwnProperty('hasGovernmentId') && userInfo?.hasOwnProperty('token')) {
      try {
        const personalInfoResponse = await sendRequestAsCallback({
          endpoint: `${process.env.REACT_APP_OFFICE_BE_PH_URL}/v1/user/me`,
          method: 'GET',
          addPrefix: false
        });
        clientHasGovernmentId = !!personalInfoResponse.data.data.flags.hasGovernmentId;
      } catch (error) {
        clientHasGovernmentId = true;
      }
    } else {
      clientHasGovernmentId = !!userInfo.hasGovernmentId;
    }

    !clientHasGovernmentId && (userInfo?.countryCode === 'US' || userInfo?.countryCode === 'PR') ?
      addActionable(ssnTinActionablePath) :
      removeActionable(ssnTinActionablePath);
  }, [sendRequestAsCallback]);

  const addThemeActionable = useCallback(() => {
    const betaVisited = JSON.parse(localStorage.getItem('isBetaVisited') || '[]');
    if (!betaVisited.includes('theme')) {
      addActionable(['settings', 'theme']);
    }
  }, []);

  useEffect(() => {
    checkGovernmentId(userInfo);
    addThemeActionable();
  }, [checkGovernmentId, userInfo, addThemeActionable]);

  return { actionables, addActionable, removeActionable, isActionable, isActionableArrayOfStrings };
};

const ActionableItemsProvider = ({ children }) => {
  const actionableContext = useActionable();

  return (
    <ActionableContext.Provider value={actionableContext}>
      {children}
    </ActionableContext.Provider>
  );
};

const useActionableItemsContext = () => {
  return useContext(ActionableContext);
};

export { ActionableItemsProvider, useActionableItemsContext };