import { getCookie } from 'common/cookie';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { createContext, useContext, useEffect, useState } from 'react';
import { useAuth } from './useAuth';
import useApiWrapper from '../services/api/apiWrapper';

const PopupContext = createContext({});

export const PopupContextProvider = ({ children }) => {
  const { userInfo, refreshUserInfo } = useAuth()
  const [isFeedbackModalSkipped, setIsFeedbackModalSkipped] = useState(getCookie(`skippedSurvey_${userInfo?.unicityId}`) === 'true');
  const [isFeedbackTaken, setIsFeedbackTaken] = useState(getCookie(`tookSurvey_${userInfo?.unicityId}`) === 'true');
  const flags = useFlags()
  const api = useApiWrapper();
  const [isPopupActive, setIsPopupActive] = useState(false)
  const [titleKeys] = useState({})
  const [popupData, setPopupData] = useState([])
  const [activePopupTargetCountries, setActivePopupTargetCountries] = useState([])

  useEffect(() => {
    if (!userInfo?.loggedIn) return
    const isPopupListHasActivePopups = flags?.showPopupModal?.some(item => item.isActive)
    if (!isPopupListHasActivePopups) {
      setIsPopupActive(false)
      return
    }
    const activePopups = flags.showPopupModal.filter(item => item.isActive)
    // Get the target countries for all active popups, then remove duplicates
    const activePopupTargetCountries = [...new Set(activePopups.map(item => item.targetCountries).flat())]
    // Check if the user is in any of the target countries
    const isUserInTargetCountry = activePopupTargetCountries.includes(userInfo.countryCode)
    const mappedActivePopups = activePopups.map(item => {
      if (userInfo?.customAttributes) {
        if (item.contentId in userInfo?.customAttributes?.popup) {
          return { ...item, ...userInfo?.customAttributes?.popup[item.contentId] }
        }
      }
      return item
    })
    setActivePopupTargetCountries(activePopupTargetCountries)
    setIsPopupActive(isPopupListHasActivePopups && isUserInTargetCountry)
    setPopupData(mappedActivePopups)
    // eslint-disable-next-line
  }, [flags, userInfo])

  useEffect(() => {
    if (!userInfo && !userInfo?.unicityId) return
    setIsFeedbackModalSkipped(getCookie(`skippedSurvey_${userInfo?.unicityId}`) === 'true')
    setIsFeedbackTaken(getCookie(`tookSurvey_${userInfo?.unicityId}`) === 'true')
  }, [userInfo?.unicityId, userInfo])

  // mode can be 'card', 'permanent, 'both'
  const handleClosePopup = async () => {
    // Fake the close popup until the backend call is still pending
    setPopupData(prev => {
      const _copy = [...prev]
      return _copy.map(item => ({ ...item, isClosed: false, isCardForm: true }))
    })

    // popupId should be dynamic, this endpoint should support both card-form and permanent closure in future iterations
    await api.put(`${process.env.REACT_APP_OFFICE_BE_PH_URL}/v1/user/${userInfo?.unicityId}/popup-attributes`,
      {
        popupId: "focus-mode",
        isClosed: false,
        isCardForm: true
      },
      {
        addPrefix: false,
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json',
        },
        withCredentials: true,
      }
    )
    refreshUserInfo(userInfo.token)
  }

  return (
    <PopupContext.Provider value={{
      isFeedbackModalSkipped,
      setIsFeedbackModalSkipped,
      isFeedbackTaken,
      setIsFeedbackTaken,
      isPopupActive,
      titleKeys,
      popupData,
      handleClosePopup,
      activePopupTargetCountries
    }}>
      {children}
    </PopupContext.Provider>
  )
}

export const usePopupContext = () => {
  const ctx = useContext(PopupContext);

  if (!ctx) throw new Error('Error in PopupContext');

  return ctx;
}